import React from "react";
import NextPage from "../../shared/components/next-page/next-page.component";
import Timeline from "../../shared/components/timeline/timeline.component";
import hero from "../../../assets/rotonde.webp";

import ceremonyIcon from "../../../assets/icons/podium-sharp-solid.svg";
import vineIcon from "../../../assets/icons/champagne-glasses-solid.svg";
import meatIcon from "../../../assets/icons/meat-solid.svg";
import partyIcon from "../../../assets/icons/martini-glass-solid.svg";

import vineImage from "../../../assets/vine.webp";
import mealImage from "../../../assets/meal.webp";
import partyImage from "../../../assets/party.webp";

import "./program.layout.scss";
class ProgramLayout extends React.Component {
  constructor(props) {
    super(props);
    this.steps = [
      {
        time: "15h00",
        title: "Cérémonie",
        content: (
          <>
            <p>
              La cérémonie commencera à 15h00 en compagnie de nos amis et notre
              famille dont vous faites partie
            </p>
          </>
        ),
        icon: ceremonyIcon,
        image: hero,
        imagePosition: "center center",
      },
      {
        time: "16h00",
        title: "Vin d'honneur",
        content: (
          <>
            <p>
              A 16h00, nous vous convions au vin d'honneur et à un apéritif afin
              de célébrer notre union et partager un moment convivial.
            </p>
            <p>
              Nous en profiterons pour faire quelques photos en votre compagnie.
            </p>
          </>
        ),
        icon: vineIcon,
        image: vineImage,
        imagePosition: "50% 24%",
      },
      {
        time: "19h00",
        title: "Repas",
        content: (
          <>
            <p>
              A 19h00, nous passerons à table et partagerons ensemble un
              magnifique repas.
            </p>
          </>
        ),
        icon: meatIcon,
        image: mealImage,
        imagePosition: "50% 42%",
      },
      {
        time: "23h00",
        title: "Soirée",
        content: (
          <>
            <p>
              Ensuite, nous commencerons la soirée par l'ouverture du bal à
              23h00
            </p>
          </>
        ),
        icon: partyIcon,
        image: partyImage,
        imagePosition: "50% 32%",
      },
    ];
    this.state = {
      currentOpen: null,
    };
  }
  onChangePanel = (index) => {
    this.setState({ currentOpen: index });
  };
  render() {
    return (
      <div className="program">
        <div className="header">
          <h2>Programme</h2>
          <div
            className="hero"
            style={{
              backgroundImage: `url(${
                this.state.currentOpen
                  ? this.steps[this.state.currentOpen].image
                  : hero
              })`,
              backgroundPosition: this.state.currentOpen
                ? this.steps[this.state.currentOpen].imagePosition
                : "50% 80%",
            }}
          ></div>
        </div>
        <div className="content">
          <Timeline steps={this.steps} onChange={this.onChangePanel} />
          <div className="actions">
            <NextPage index={2} />
          </div>
        </div>
      </div>
    );
  }
}
export default ProgramLayout;
