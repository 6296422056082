import React from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import NextPage from "../../shared/components/next-page/next-page.component";

import Button from "../../shared/components/button/button.component";
import Input from "../../shared/components/input/input.component";
import Switch from "../../shared/components/switch/switch.component";
import TextArea from "../../shared/components/textarea/textarea.component";
import "./subscription.layout.scss";

import hero from "../../../assets/inscription.webp";
import Modal from "../../shared/components/modal/modal.component";

class SubscriptionLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProfile: null,
      form: {},
      valid: true,
    };
  }
  onOpenModal = (item) => {
    this.setState({ currentProfile: item });
  };
  onCloseModal = () => {
    this.setState({ currentProfile: null });
  };
  onChangeField = (value) => {
    var form = this.state.form;
    form[value.id] = value;
    var valid = true;
    Object.keys(form).every((key) => {
      if (!form[key].valid) {
        valid = false;
        return false;
      }
      return valid;
    });
    this.setState({ form: form, valid: valid });
  };
  onSubmitModal = () => {
    if (this.state.valid && Object.keys(this.state.form).length > 0) {
      const BASE_API_URL = "https://mariage-manager.nemesix.com/wp-json/";
      var myHeaders = new Headers();
      var data = this.state.form;
      data.uid = this.state.currentProfile.uid;
      var content = JSON.stringify(data);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Content-Length", content.length.toString());
      var args = {
        method: "PUT",
        headers: myHeaders,
        body: content,
      };
      fetch(BASE_API_URL + "profile/V1/edit", args)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ currentProfile: null, open: false, form: {} });
          if (data !== false) {
            if (
              data.acf &&
              data.acf.uid &&
              data.acf.uid === this.props.profileReducer.profile.acf.uid
            ) {
              const action = {
                type: "CONNECTED",
                module: "profile",
                args: data,
              };
              this.props.dispatch(action);
            } else if (
              data.acf &&
              data.acf.uid &&
              this.props.profileReducer.profile.acf.relations.find(
                (relation) => relation.acf.uid === data.acf.uid
              ) !== undefined
            ) {
              var relations = this.props.profileReducer.profile.acf.relations;
              var relation = relations.find(
                (relation) => relation.acf.uid === data.acf.uid
              );
              // relation = data;
              console.log({
                type: "CONNECTED",
                module: "profile",
                args: {
                  ...this.props.profileReducer.profile,
                  relations: relations,
                },
              });
              const action = {
                type: "CONNECTED",
                module: "profile",
                args: {
                  ...this.props.profileReducer.profile,
                  relations: relations,
                },
              };
              this.props.dispatch(action);
            } else {
              console.log(this.props.profileReducer.profile.acf.relations);
            }
            var notification = {
              type: "OPEN_NOTIFICATION",
              module: "notification",
              args: {
                uid: uuidv4(),
                title: "Sauvegardé avec succès",
              },
            };
            this.props.dispatch(notification);
            setTimeout(() => {
              notification.type = "CLOSE_NOTIFICATION";
              this.props.dispatch(notification);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  render() {
    return (
      <>
        <div className="mainBox subscription">
          <>
            <div className="header">
              <h2>Inscription</h2>
              <div
                className="hero"
                style={{ backgroundImage: `url(${hero})` }}
              ></div>
            </div>

            <p>
              Tu as un ou plusieurs boutons en dessous, ils te permettent de
              t'inscrire toi et ta famille et de nous faciliter l'organisation.
            </p>
          </>
          <div className="content">
            <div className="buttons">
              <Button
                onClick={() => {
                  this.onOpenModal(this.props.profileReducer.profile.acf);
                }}
              >
                {this.props.profileReducer.profile.acf.firstname}{" "}
                {this.props.profileReducer.profile.acf.lastname}
              </Button>
              {this.props.profileReducer.profile.acf.relations &&
                this.props.profileReducer.profile.acf.relations.map(
                  (relation) => {
                    return (
                      <Button
                        onClick={() => {
                          this.onOpenModal(relation.acf);
                        }}
                        key={relation.ID}
                      >
                        {relation.acf.firstname + " " + relation.acf.lastname}
                      </Button>
                    );
                  }
                )}
            </div>
            <div className="actions">
              <NextPage index={3} />
            </div>
          </div>
        </div>
        <Modal
          title={
            this.props.profileReducer.profile.acf.firstname +
            " " +
            this.props.profileReducer.profile.acf.lastname
          }
          open={
            this.state.currentProfile &&
              this.state.currentProfile.uid ===
              this.props.profileReducer.profile.acf.uid
              ? true
              : false
          }
          onClose={this.onCloseModal}
        >
          <div className="modalForm">
            <Input
              id="firstname"
              placeHolder="Prénom"
              value={this.props.profileReducer.profile.acf.firstname}
              onChange={this.onChangeField}
              required
            />
            <Input
              id="lastname"
              placeHolder="Nom"
              value={this.props.profileReducer.profile.acf.lastname}
              onChange={this.onChangeField}
              required
            />
            <Input
              id="address"
              placeHolder="Adresse"
              value={this.props.profileReducer.profile.acf.address}
              onChange={this.onChangeField}
              required
            />
            <Input
              id="npa"
              placeHolder="NPA"
              value={this.props.profileReducer.profile.acf.npa}
              onChange={this.onChangeField}
              required
            />
            <Input
              id="town"
              placeHolder="Ville"
              value={this.props.profileReducer.profile.acf.town}
              onChange={this.onChangeField}
              required
            />

            <div className="switchBox">
              <div className="switchLabel">Présent à la cérémonie ?</div>
              <Switch
                id="present"
                value={this.props.profileReducer.profile.acf.present}
                onChange={this.onChangeField}
              />
            </div>
            <div className="switchBox">
              <div className="switchLabel">Présent au souper ?</div>
              <Switch
                id="present_diner"
                value={this.props.profileReducer.profile.acf.present_diner}
                onChange={this.onChangeField}
              />
            </div>
            <div className="switchBox">
              <div className="switchLabel">Veux-tu dormir sur place ?</div>
              <Switch
                id="sleep"
                value={this.props.profileReducer.profile.acf.sleep}
                onChange={this.onChangeField}
              />
            </div>
            <TextArea
              id="allergy"
              placeHolder="Régime alimentaire / Intolérances / Allergies"
              value={this.props.profileReducer.profile.acf.allergy}
              onChange={this.onChangeField}
            />
            <TextArea
              id="comment"
              placeHolder="Commentaires"
              value={this.props.profileReducer.profile.acf.comment}
              onChange={this.onChangeField}
            />
            {this.state.valid ? (
              <Button onClick={this.onSubmitModal}>Enregistrer</Button>
            ) : (
              <div>Erreur dans le formulaire</div>
            )}
          </div>
        </Modal>
        {this.props.profileReducer.profile.acf.relations.map((item, index) => {
          return (
            <div key={index}>
              <Modal
                title={item.acf.firstname + " " + item.acf.lastname}
                open={
                  this.state.currentProfile &&
                    this.state.currentProfile.uid === item.acf.uid
                    ? true
                    : false
                }
                onClose={this.onCloseModal}
              >
                <div className="modalForm">
                  <Input
                    id="firstname"
                    placeHolder="Prénom"
                    value={item.acf.firstname}
                    onChange={this.onChangeField}
                    required
                  />
                  <Input
                    id="lastname"
                    placeHolder="Nom"
                    value={item.acf.lastname}
                    onChange={this.onChangeField}
                    required
                  />
                  <Input
                    id="address"
                    placeHolder="Adresse"
                    value={item.acf.address}
                    onChange={this.onChangeField}
                    required
                  />
                  <Input
                    id="npa"
                    placeHolder="NPA"
                    value={item.acf.npa}
                    onChange={this.onChangeField}
                    required
                  />
                  <Input
                    id="town"
                    placeHolder="Ville"
                    value={item.acf.town}
                    onChange={this.onChangeField}
                    required
                  />

                  <div className="switchBox">
                    <div className="switchLabel">Présent à la cérémonie ?</div>
                    <Switch
                      id="present"
                      value={item.acf.present}
                      onChange={this.onChangeField}
                    />
                  </div>
                  <div className="switchBox">
                    <div className="switchLabel">Présent au souper ?</div>
                    <Switch
                      id="present_diner"
                      value={item.acf.present_diner}
                      onChange={this.onChangeField}
                    />
                  </div>
                  <div className="switchBox">
                    <div className="switchLabel">
                      Veux-tu dormir sur place ?
                    </div>
                    <Switch
                      id="sleep"
                      value={item.acf.sleep}
                      onChange={this.onChangeField}
                    />
                  </div>
                  <TextArea
                    id="allergy"
                    placeHolder="Régime alimentaire / Intolérences / Allergies"
                    value={item.acf.allergy}
                    onChange={this.onChangeField}
                  />
                  <TextArea
                    id="comment"
                    placeHolder="Commentaires"
                    value={item.acf.comment}
                    onChange={this.onChangeField}
                  />
                  {this.state.valid ? (
                    <Button onClick={this.onSubmitModal}>Enregistrer</Button>
                  ) : (
                    <div>Erreur dans le formulaire</div>
                  )}
                </div>
              </Modal>
            </div>
          );
        })}
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(SubscriptionLayout);
