import React from "react";
import NextPage from "../../shared/components/next-page/next-page.component";
import "./home.layout.scss";

import hero from "../../../assets/ceremony.webp";

class HomeLayout extends React.Component {
  render() {
    return (
      <div className="mainBox home">
        <div className="hero" style={{ backgroundImage: `url(${hero})` }}></div>
        <div className="content">
          <div>
            <h1>G&F</h1>
            <p>
              Bienvenue, tu peux retrouver les photos de cette magnifique journée que nous avons passé avec toi.
            </p>
          </div>
          <div className="actions">
            <NextPage index={1} />
          </div>
        </div>
      </div>
    );
  }
}
export default HomeLayout;
