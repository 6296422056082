const initialState = {
  notifications: [],
};

function appReducer(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case "OPEN_NOTIFICATION":
      const newNotifications = [...state.notifications, action.args];
      nextState = {
        ...state,
        notifications: newNotifications,
      };
      return nextState || state;
    case "CLOSE_NOTIFICATION":
      const updatedNotifications = state.notifications.filter(
        (notification) => notification.uid !== action.args.uid
      );
      nextState = {
        ...state,
        notifications: updatedNotifications,
      };

      return nextState || state;
    default:
      return state;
  }
}
export default appReducer;
