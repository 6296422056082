import React from "react";
import { connect } from "react-redux";

import closeIcon from "../../../../assets/icons/xmark-solid.svg";
import "./notification.component.scss";

class Notification extends React.Component {
  onCloseNotification = (uid) => {
    const action = {
      type: "CLOSE_NOTIFICATION",
      module: "notification",
      args: { uid: uid },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <>
        <div className="notification">
          <div className="notificationsWrapper">
            {this.props.notificationReducer.notifications.map(
              (notification) => {
                return (
                  <div className="notificationItem" key={notification.uid}>
                    <div className="title">{notification.title}</div>
                    <div
                      className="actions"
                      onClick={() => {
                        this.onCloseNotification(notification.uid);
                      }}
                    >
                      <img src={closeIcon} className="closeIcon" alt="" />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Notification);
