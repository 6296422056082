import React from "react";
import Map from "../../shared/components/map/map.component";
import Faq from "../../shared/components/faq/faq.component";
import "./informations.layout.scss";

import parkingIcon from "../../../assets/icons/square-parking-solid.svg";
import meatIcon from "../../../assets/icons/meat-solid.svg";
import sleepIcon from "../../../assets/icons/bed-front-sharp-solid.svg";
import friendIcon from "../../../assets/icons/user-plus-solid.svg";
import childrenIcon from "../../../assets/icons/child-reaching-solid.svg";
import NextPage from "../../shared/components/next-page/next-page.component";

class InformationsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.questions = [
      {
        id: 1,
        title: "Parking à disposition ?",
        icon: parkingIcon,
        content: (
          <p>
            Un parking est à disposition, mais nous vous recommandons de
            privilégier le covoiturage.
          </p>
        ),
      },
      {
        id: 2,
        title:
          "J'ai des allergies ou un régime alimentaire spécifique, y a-t-il un menu adapté ?",
        icon: meatIcon,
        content: (
          <p>
            Oui il te suffit de l'indiquer dans le champ "Régime alimentaire /
            Intolérances / Allergies" lors de ton inscription.
          </p>
        ),
      },
      {
        id: 3,
        title: "Peut-on dormir sur place ?",
        icon: sleepIcon,
        content: (
          <p>
            Il est possible de dormir sur place, il te suffit de l'indiquer dans
            ton inscription.
          </p>
        ),
      },
      {
        id: 4,
        title: "Est-ce que les enfants sont invités ?",
        icon: childrenIcon,
        content: (
          <>
            <p>
              Pour des questions d'organisation, de places et de budget, nous
              avons choisi de limiter les invitations jusqu'aux cousins.
            </p>
            <p>
              C'est pourquoi, on serait reconnaissant que vous puissiez venir
              sans enfants dans la mesure du possible, merci de nous contacter
              si ce n'est pas le cas.
            </p>
          </>
        ),
      },
      {
        id: 5,
        title: "Peut-on inviter une personne ?",
        icon: friendIcon,
        content: (
          <>
            <p>
              Pour des questions de place et de choix, il n'est pas prévus
              d'inviter d'autres personnes.
            </p>
            <p>
              Néanmoins, si tu penses que nous avons oublié une personne,
              contacte-nous d'ici au 15 janvier 2024.
            </p>
          </>
        ),
      },
    ];
  }
  render() {
    return (
      <div className="mainBox informations">
        <h2>Infos pratiques</h2>
        <div className="content">
          <Faq questions={this.questions} />
          <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1587.1012571364392!2d6.747423810443429!3d46.870795145108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ddc62f6efa1f1%3A0x6ab82611cb4b3ecc!2sFondation%20le%20Camp!5e0!3m2!1sfr!2sch!4v1695990485550!5m2!1sfr!2sch" />
        </div>
        <div className="actions">
          <NextPage index={5} />
        </div>
      </div>
    );
  }
}
export default InformationsLayout;
