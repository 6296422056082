import React from "react";
import "./button.component.scss";

class Button extends React.Component {
  render() {
    return (
      <div
        className="button"
        onClick={this.props.onClick ? this.props.onClick : () => {}}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Button;
