import React from "react";
import "./countdown.component.scss";

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    };
    this.timer = window.setInterval(() => {
      this.tick();
    }, 1000);
  }
  tick = () => {
    const eventDate = new Date("2024-07-06 14:00:00");
    const now = new Date().getTime();
    const distance = eventDate.getTime() - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };
  render() {
    return (
      <div className="countdown">
        <div className="panel">
          <div className="number">{this.state.days}</div>
          <div className="label">Jour{this.state.days > 1 && "s"}</div>
        </div>
        <div className="panel">
          <div className="number">{this.state.hours}</div>
          <div className="label">Heure{this.state.hours > 1 && "s"}</div>
        </div>
        <div className="panel">
          <div className="number">{this.state.minutes}</div>
          <div className="label">Minute{this.state.minutes > 1 && "s"}</div>
        </div>
        <div className="panel">
          <div className="number">{this.state.seconds}</div>
          <div className="label">Seconde{this.state.seconds > 1 && "s"}</div>
        </div>
      </div>
    );
  }
}
export default Countdown;
