import React from "react";
import "./gallery-viewer.component.scss";
import closeIcon from "../../../../assets/icons/xmark-solid.svg";
import { useSwipeable } from "react-swipeable";

class GalleryViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      selectedImageIndex: null,
    };
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }
  handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      this.moveToPreviousImage();
    } else if (event.key === "ArrowRight") {
      this.moveToNextImage();
    }
  };
  onSelectItem = (item, index) => {
    this.setState({
      selectedImage: item,
      selectedImageIndex: index
    })
  }
  handleSwipedLeft = () => {
    this.moveToNextImage();
  };
  handleSwipedRight = () => {
    this.moveToPreviousImage();
  };
  moveToPreviousImage = () => {
    if (this.state.selectedImageIndex - 1 < 0) {
      this.setState({
        selectedImage: this.props.item.acf.gallery[this.props.item.acf.gallery.length - 1],
        selectedImageIndex: this.props.item.acf.gallery.length - 1
      })
    }
    else {
      this.setState({
        selectedImage: this.props.item.acf.gallery[this.state.selectedImageIndex - 1],
        selectedImageIndex: this.state.selectedImageIndex - 1
      })
    }
  }
  moveToNextImage = () => {
    if (this.state.selectedImageIndex + 1 >= this.props.item.acf.gallery.length) {
      this.setState({
        selectedImage: this.props.item.acf.gallery[0],
        selectedImageIndex: 0
      })
    }
    else {
      this.setState({
        selectedImage: this.props.item.acf.gallery[this.state.selectedImageIndex + 1],
        selectedImageIndex: this.state.selectedImageIndex + 1
      })
    }
  }
  render() {
    return (
      <div className="gallery-viewer">
        <div className="gallery-viewer-box">
          <div className="gallery-viewer-header">
            <div className="gallery-viewer-header-title">{this.props.item.post_title}</div>
            <div className="gallery-viewer-header-actions">
              <div className="gallery-viewer-header-actions-item" onClick={this.props.onClose}>
                <img src={closeIcon} className="closeIcon" alt="" />
              </div>
            </div>
          </div>

          {this.state.selectedImage ? (
            <div
              className="gallery-viewer-image-box"
              onClick={() => { this.onSelectItem(null) }}
            >
              <SwipeableImage
                image={this.state.selectedImage}
                onSwipedLeft={this.handleSwipedLeft}
                onSwipedRight={this.handleSwipedRight}
                onClick={() => this.onSelectItem(null)}
              />
            </div>
          ) : (
            <div className="gallery-viewer-wrapper">
              {this.props.item.acf.gallery.map((item, index) => {
                return (<div key={item.ID} className="gallery-viewer-wrapper-item" style={{ backgroundImage: "url('" + item.url + "')" }} onClick={() => { this.onSelectItem(item, index); }}>

                </div>)
              })}
            </div>
          )}

        </div>
      </div>
    );
  }
}

const SwipeableImage = ({ image, onSwipedLeft, onSwipedRight, onClick }) => {
  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
  });

  return (
    <div className="gallery-viewer-image-box" {...handlers} onClick={onClick}>
      <img src={image.url} alt="" />
    </div>
  );
};

export default GalleryViewer;
