import React from "react";
import "./gallery-slider.component.scss";
import GallerySliderItem from "./components/gallery-slider-item/gallery-slider-item.component";
import GalleryViewer from "../gallery-viewer/gallery-viewer.component";

class GallerySlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGalery: null,
    };
  }
  onOpenGallery = (gallery) => {
    this.setState({
      selectedGalery: gallery
    })
  };
  onCloseGallery = () => {
    this.setState({
      selectedGalery: null
    })
  }
  render() {
    return (
      <>
        <div className="gallery-slider">
          <div className="wrapper">
            {this.props.items.map((item) => {
              return (
                <div className="item" key={item.ID}>
                  <GallerySliderItem item={item} onClick={this.onOpenGallery} />
                </div>
              );
            })}
          </div>
        </div>
        {
          this.state.selectedGalery && (
            <GalleryViewer item={this.state.selectedGalery} onClose={this.onCloseGallery} />
          )
        }

      </>

    );
  }
}
export default GallerySlider;
