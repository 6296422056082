import React from "react";
import "./next-page.component.scss";

import icon from "../../../../assets/icons/arrow-down-solid.svg";

class NextPage extends React.Component {
  onClick = () => {
    window.scrollTo({
      top: this.props.index ? this.props.index * window.innerHeight : 0, // 100vh
      behavior: "smooth", // Use smooth scrolling for a smoother effect
    });
  };
  render() {
    return (
      <div
        className="nextPage"
        onClick={() => {
          this.onClick();
        }}
      >
        <img className="icon" src={icon} alt="" />
      </div>
    );
  }
}
export default NextPage;
