import Store from "./modules/core/stores/configureStore";
import { Provider } from "react-redux";
import Interface from "./modules/core/components/interface/interface.component";
import "./app.scss";

function App() {
  return (
    <Provider store={Store}>
      <div className="App">
        <Interface />
      </div>
    </Provider>
  );
}

export default App;
