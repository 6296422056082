import React from "react";
import "./switch.component.scss";

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : false,
    };
  }
  render() {
    return (
      <div
        className={this.state.value ? "switch active" : "switch"}
        onClick={
          this.props.id && this.props.onChange
            ? () => {
                this.setState({ value: !this.state.value });
                this.props.onChange({
                  id: this.props.id,
                  value: !this.state.value,
                  valid: true,
                });
              }
            : console.error("id or onChange props required")
        }
      >
        <div></div>
      </div>
    );
  }
}
export default Switch;
