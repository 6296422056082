import React from "react";
import "./timeline.component.scss";

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPanel: null,
    };

    this.started = false;
    this.timer = null;

    this.autoStartTimer = this.props.autostart
      ? window.setTimeout(() => {
          !this.started && this.start();
        }, 10000)
      : null;
  }
  start = () => {
    this.started = true;

    this.timer = window.setInterval(() => {
      this.onSelectPanel(
        this.state.currentPanel >= 0
          ? this.state.currentPanel > 0
            ? this.props.steps[this.state.currentPanel + 1]
              ? this.state.currentPanel + 1
              : 0
            : 1
          : 0,
        true
      );
    }, 3000);
  };
  stop = () => {
    this.started = false;
    clearInterval(this.timer);
  };
  onSelectPanel = (index, next) => {
    this.started = true;
    next !== true && this.stop();
    this.setState({
      currentPanel: this.state.currentPanel === index ? null : index,
    });

    this.props.onChange
      ? this.props.onChange(this.state.currentPanel === index ? null : index)
      : console.error("No on change prop");
  };
  render() {
    return (
      <div className="timeline">
        {this.props.steps && this.props.steps.length > 0 ? (
          <>
            <div className="selectors">
              {this.props.steps.map((step, index) => {
                return (
                  <div className="selectorBox" key={index}>
                    <div
                      className="selector"
                      onClick={() => {
                        this.onSelectPanel(index);
                      }}
                    >
                      <div className="label">{step.time}</div>
                      <img
                        className={
                          this.state.currentPanel === index
                            ? "icon current"
                            : this.state.currentPanel !== null &&
                              this.state.currentPanel + 1 === index
                            ? "icon blink"
                            : !this.state.currentPanel && index === 0
                            ? "icon blink"
                            : "icon"
                        }
                        src={step.icon}
                        alt=""
                      />
                    </div>
                    {index < this.props.steps.length - 1 && (
                      <div className="separator">
                        <div className="content"></div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="panel">
              {this.state.currentPanel || this.state.currentPanel === 0 ? (
                <>
                  <div className="panelTitle">
                    {this.props.steps[this.state.currentPanel].title}
                  </div>
                  <div className="panelContent">
                    {this.props.steps[this.state.currentPanel].content}
                  </div>
                </>
              ) : (
                <>
                  <p className="unselect">
                    Sélectionne une étape de la journée dans le programme pour
                    en savoir plus
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <div>no steps props</div>
        )}
      </div>
    );
  }
}
export default Timeline;
