import React from "react";
import "./input-date.module.scss";
// import DateTimePicker from "@react-native-community/datetimepicker";

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
    };
  }
  onOpenDatePicker = () => {
    this.setState({ showDatePicker: true });
  };
  onCloseDatePicker = () => {
    this.setState({ showDatePicker: false });
  };
  onChange = (value) => {
    if (value.type === "set") {
      var date = new Date(value.nativeEvent.timestamp);
      this.props.onChange !== undefined &&
        typeof this.props.onChange === "function" &&
        this.props.onChange(date);
    }
    this.onCloseDatePicker();
  };
  render() {
    return <div className="mainBox"></div>;
  }
}
// function pad2(number) {
//   return (number < 10 ? "0" : "") + number;
// }
export default DateInput;
