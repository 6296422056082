const initialState = {
  galleries: [],
};

function galleryReducer(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case "UPDATE_GALLERIES":
      nextState = {
        ...state,
        galleries: action.args,
      };

      return nextState || state;
    default:
      return state;
  }
}
export default galleryReducer;
