const initialState = {
  version: "V1.0.0",
};

function appReducer(state = initialState, action) {
  // let nextState;

  switch (action.type) {
    default:
      return state;
  }
}
export default appReducer;
