import React from "react";
import "./faq.component.scss";

import upIcon from "../../../../assets/icons/chevron-up-solid.svg";
import downIcon from "../../../../assets/icons/chevron-down-solid.svg";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestionOpen: null,
    };
  }
  onOpenQuestion(question) {
    question =
      this.state.currentQuestionOpen &&
      this.state.currentQuestionOpen.id === question.id
        ? null
        : question;

    this.setState({ currentQuestionOpen: question });
  }
  render() {
    return (
      <div className="faq">
        <div className="infosWrapper">
          {this.props.questions.map((question) => {
            return (
              <div className="info" key={question.id}>
                <div
                  className="infoHeader"
                  onClick={() => {
                    this.onOpenQuestion(question);
                  }}
                >
                  <div className="infoHeaderIcon">
                    <img src={question.icon} alt="" />
                  </div>
                  <div className="infoHeaderTitle">{question.title}</div>
                  <div className="infoHeaderIcon">
                    <img
                      src={
                        this.state.currentQuestionOpen &&
                        this.state.currentQuestionOpen.id === question.id
                          ? upIcon
                          : downIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    this.state.currentQuestionOpen &&
                    this.state.currentQuestionOpen.id === question.id
                      ? "infoContent active"
                      : "infoContent"
                  }
                >
                  {question.content}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default Faq;
