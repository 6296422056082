import React from "react";
import { connect } from "react-redux";

import NextPage from "../../shared/components/next-page/next-page.component";
import "./countdown.layout.scss";
import Countdown from "../../shared/components/countdown/countdown.component";
import heartIcon from "../../../assets/icons/heart-solid.svg";
class CountdownLayout extends React.Component {
  render() {
    return (
      <div className="mainBox countdownLayout">
        <p className="footer">
          <span className="firstname">
            {this.props.profileReducer.profile.acf.firstname}
          </span>
          , nous nous réjouissons de pouvoir passer cette magnifique journée
          avec toi, il ne reste plus que :
        </p>
        <div className="countdownBox">
          <Countdown />
        </div>
        <div className="heartBox">
          <img src={heartIcon} alt="" />
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(CountdownLayout);
