import React from "react";
import "./gallery-slider-item.component.scss";

class GallerySliderItem extends React.Component {
  render() {
    return (
      <div
        className="gallery-slider-item"
        onClick={() => {
          this.props.onClick(this.props.item);
        }}
      >
        <div
          className="image-box"
          style={
            this.props.item.acf &&
            this.props.item.acf.gallery &&
            this.props.item.acf.gallery[0] &&
            this.props.item.acf.gallery[0]["url"] && {
              backgroundImage:
                "url('" + this.props.item.acf.gallery[0]["url"] + "')",
            }
          }
        ></div>
        <div className="title">{this.props.item.post_title}</div>
      </div>
    );
  }
}
export default GallerySliderItem;
