import React from "react";
import "./modal.component.scss";

import xIcon from "../../../../assets/icons/xmark-solid.svg";

class Modal extends React.Component {
  render() {
    return (
      <div className={this.props.open ? "modal open" : "modal"}>
        <div className="modalBox">
          <div className="modalHeader">
            <div className="modalHeaderTitle">
              {this.props.title ? this.props.title : ""}
            </div>
            <div
              className="modalHeaderCloseBox"
              onClick={this.props.onClose ? this.props.onClose : () => {}}
            >
              <img className="modalHeaderCloseBoxImage" src={xIcon} alt="X" />
            </div>
          </div>
          <div className="modalContent">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default Modal;
