import React from "react";
import { connect } from "react-redux";
import Layout from "../layout/layout.component";
import HomeLayout from "../../../layout/home/home.layout";
import ProgramLayout from "../../../layout/program/program.layout";
import SubscriptionLayout from "../../../layout/subscription/subscription.layout";
import InformationsLayout from "../../../layout/informations/informations.layout";
import GalleryLayout from "../../../layout/gallery/gallery.layout";
import Login from "../login/login.component";
import "./interface.component.scss";
import CountdownLayout from "../../../layout/countdown/countdown.layout";

class Interface extends React.Component {
  render() {
    return (
      <div className="interface">
        {this.props.profileReducer.connected ? (
          <>
            <Layout>
              <HomeLayout />
            </Layout>
            {/* <Layout>
              <ProgramLayout />
            </Layout>
            <Layout>
              <SubscriptionLayout />
            </Layout> */}
            <Layout>
              <GalleryLayout />
            </Layout>
            {/* <Layout>
              <InformationsLayout />
            </Layout> */}
            {/* <Layout>
              <CountdownLayout />
            </Layout> */}
          </>
        ) : (
          <div className="error">
            <Login />
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Interface);
