import React from "react";
import { connect } from "react-redux";
import NextPage from "../../shared/components/next-page/next-page.component";
import GallerySlider from "../../shared/components/gallery-slider/gallery-slider.component";

import "./gallery.layout.scss";

import hero from "../../../assets/galery.webp";

class GalleryLayout extends React.Component {
  constructor(props) {
    super(props);
    const BASE_API_URL = "https://mariage-manager.nemesix.com/wp-json/";
    fetch(BASE_API_URL + "gallery/V1/list")
      .then((response) => response.json())
      .then((data) => {
        const action = {
          type: "UPDATE_GALLERIES",
          module: "gallery",
          args: data,
        };
        this.props.dispatch(action);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  render() {
    const profile = this.props.profileReducer.profile;
    const galleries = profile.acf.present_civil === true ? this.props.galleryReducer.galleries : this.props.galleryReducer.galleries.filter((galery) => galery.acf.civil === false);
    return (
      <div className="mainBox gallery">
        <div className="header">
          <h2>Galeries</h2>
          {/* <div
            className="hero"
            style={{ backgroundImage: `url(${hero})` }}
          ></div> */}
        </div>

        <div className="content">
          <p>
            Tu trouveras dans cette section les photos officielles du mariage
            ainsi que celles que vous avez prises durant cette magnifique
            journée
          </p>
          <GallerySlider items={galleries} />
          <div className="actions">
            {/* <NextPage index={4} /> */}
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(GalleryLayout);
