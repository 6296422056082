const initialState = {
  connected: false,
  profile: {},
};

function profileReducer(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case "CONNECTED":
      nextState = {
        ...state,
        connected: true,
        profile: action.args,
      };

      return nextState || state;

    default:
      return state;
  }
}
export default profileReducer;
