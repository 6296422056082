import React from "react";
import "./layout.component.scss";
import Notification from "../notification/notification.component";

class Layout extends React.Component {
  render() {
    return (
      <>
        <div className="layout">{this.props.children}</div>
        <Notification />
      </>
    );
  }
}
export default Layout;
