import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import "./map.component.scss";

const googleMapsAPIKey = "AIzaSyC0EzuqnsRVpWfHkNz3XuQYgVXSA7souxY";

const containerStyle = {
  width: "100%",
  height: "100%",
};
class MapContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="map" id="google_map">
        <div className="mapWrapper">
          <Map
            initialCenter={{
              lat: 46.87047,
              lng: 6.74897,
            }}
            containerStyle={containerStyle}
            google={this.props.google}
            zoom={17.5}
            zoomControl={false}
            mapTypeControl={false}
            streetViewControl={false}
          >
            <Marker onClick={this.onMarkerClick} name={"Current location"} />

            <InfoWindow onClose={this.onInfoWindowClose}>
              <div>
                <h1>aaa</h1>
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: googleMapsAPIKey,
})(MapContainer);
