import React from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./login.component.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);
    const path = window.location.search.split("=");
    if (path[0] === "?uid" && path[1]) {
      const uid = path[1];
      this.login(uid);
    }
    else if (
      localStorage.getItem("uid") &&
      !this.props.profileReducer.connected
    ) {
      const uid = localStorage.getItem("uid");
      this.login(uid);
    }


    this.state = {
      selectProfileModalOpen: false,
      profile: null,
    };
  }
  login = (uid, callback) => {
    const BASE_API_URL = "https://mariage-manager.nemesix.com/wp-json/";
    fetch(BASE_API_URL + "profile/V1/login?uid=" + uid)
      .then((response) => response.json())
      .then((data) => {
        if (data.ID > 0) {
          this.setState({ profile: data });
          (localStorage.getItem("uid") !== uid &&
            data.acf.relations.length > 0) ||
            (localStorage.getItem("uid") &&
              localStorage.getItem("uid") === "" &&
              data.acf.relations.length > 0)
            ? this.setState({ selectProfileModalOpen: true })
            : this.onSuccessLogin(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  onSelectProfile = (profile) => {
    this.onSuccessLogin(profile);
  };
  onSuccessLogin = (profile) => {
    this.setState({ selectProfileModalOpen: false });
    localStorage.setItem("uid", profile.acf.uid);
    const action = {
      type: "CONNECTED",
      module: "profile",
      args: profile,
    };
    this.props.dispatch(action);
    const path = window.location.search.split("=");
    if (path[0] === "?uid" && path[1]) {
      // window.location.replace("/");
      window.history.pushState("G & F - Mariage", "", "/");
    }

    var notification = {
      type: "OPEN_NOTIFICATION",
      module: "notification",
      args: {
        uid: uuidv4(),
        title: "Bonjour " + profile.acf.firstname,
      },
    };
    this.props.dispatch(notification);
    setTimeout(() => {
      notification.type = "CLOSE_NOTIFICATION";
      this.props.dispatch(notification);
    }, 3000);
  };
  render() {
    return (
      <>
        {this.state.profile ? (
          <div
            className={
              !this.state.profile ? "selectProfile" : "selectProfile active"
            }
          >
            <h1>Choisis ton profil</h1>
            <div className="wrapper">
              <div
                onClick={() => {
                  this.onSelectProfile(this.state.profile);
                }}
              >
                {this.state.profile.acf.firstname +
                  " " +
                  this.state.profile.acf.lastname}
              </div>
              {this.state.profile.acf.relations.map((relation) => {
                return (
                  <div
                    key={relation.ID}
                    onClick={() => {
                      this.onSelectProfile(relation);
                    }}
                  >
                    {relation.acf.firstname + " " + relation.acf.lastname}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <>
            <h1>Oups !</h1>
            <p>
              Il semble que ton login n'ai pas fonctionné, essais de re-scanner
              le QR code reçu dans ton faire-part
            </p>
          </>
        )}
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Login);
