import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import profileReducer from "./profile.reducer";
import notificationReducer from "./notification.reducer";
import galleryReducer from "./gallery.reducer";

export default combineReducers({
  appReducer,
  profileReducer,
  notificationReducer,
  galleryReducer,
});
